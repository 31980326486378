<template>
	<el-scrollbar class="uu-box">
		<div class="uu-return">
			<div class="uu-charts">
				<div id="myChart" style="width: 500px;height: 160px"></div>
			</div>
			<div class="uu-screen">
				<el-form v-model="screenForm" :inline="true">
					<el-form-item>
						<el-input v-model="screenForm.buyer_name" :placeholder="$t('seller.placeholder.buyerName')"
							clearable>
							<template slot="prefix">{{ $t('seller.screen.buyerName') }}</template>
						</el-input>
					</el-form-item>
					<el-form-item>
						<el-input v-model="screenForm.waybill_sn"
							:placeholder="$t('seller.placeholder.returnWaybillNo')" clearable>
							<template slot="prefix">{{ $t('seller.screen.waybillNo') }}</template>
						</el-input>
					</el-form-item>
					<el-form-item>
						<div class="uu-date-picker">
							<div class="picker-prefix-label">{{ $t('seller.screen.applyTime') }}</div>
							<el-date-picker v-model="applyTimes" type="datetimerange" range-separator="-"
								:start-placeholder="$t('seller.placeholder.originDate')"
								:end-placeholder="$t('seller.placeholder.endDate')" prefix-icon=" "
								:picker-options="startPickerOptions" value-format="timestamp">
							</el-date-picker>
						</div>
					</el-form-item>
					<el-form-item>
						<el-input v-model="screenForm.order_sn" :placeholder="$t('seller.placeholder.orderNumber')"
							clearable>
							<template slot="prefix">{{ $t('seller.screen.orderNumber') }}</template>
						</el-input>
					</el-form-item>
					<el-form-item>
						<div class="uu-date-picker">
							<div class="picker-prefix-label">{{ $t('seller.screen.latelyModify') }}</div>
							<el-date-picker v-model="modifyTimes" type="datetimerange" range-separator="-"
								:start-placeholder="$t('seller.placeholder.originDate')"
								:end-placeholder="$t('seller.placeholder.endDate')" prefix-icon=" "
								:picker-options="startPickerOptions" value-format="timestamp">
							</el-date-picker>
						</div>
					</el-form-item>
					<el-form-item>
						<div class="el-input el-input--prefix">
							<div class="el-input__inner">
								<div class="range-number">
									<input type="text" v-model="screenForm.price_from" maxlength="15"
										class="input-medium" @blur="rangeInputBlur" @focus="rangeInputFocus"
										autocomplete="off" :placeholder="$t('seller.placeholder.minimum')">
									<span class="range-separate">{{ $t('seller.unit.to') }}</span>
									<input type="text" v-model="screenForm.price_to" maxlength="15" class="input-medium"
										@blur="rangeInputBlur" @focus="rangeInputFocus" autocomplete="off"
										:placeholder="$t('seller.placeholder.highest')">
								</div>
							</div>
							<span class="el-input__prefix">{{ $t('seller.screen.refundAmount') }}</span>
						</div>
					</el-form-item>
					<el-form-item>
						<el-input v-model="screenForm.refund_sn" :placeholder="$t('seller.screen.refundNumber')"
							clearable>
							<template slot="prefix">{{ $t('seller.screen.refundNumber') }}</template>
						</el-input>
					</el-form-item>
					<el-form-item>
						<el-select v-model="screenForm.refund_time">
							<template slot="prefix">{{ $t('seller.screen.refundTime') }}</template>
							<el-option v-for="(item, index) in $t('seller.order.refundTimeOption')" :key="index" :label="item.label"
								:value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-button type="primary" @click="handelSearch" round>{{ $t('seller.actions.search') }}</el-button>
						<span class="clear-btn" @click="handleClear"><i class="el-icon-brush"></i>{{ $t('seller.actions.reset') }}</span>
					</el-form-item>
				</el-form>
			</div>

			<el-tabs v-model="activeTabs" @tab-click="handleClickTabs">
				<el-tab-pane v-for="(item, index) in refundNav" :key="index" :label="item.label" :name="item.value">
				</el-tab-pane>
			</el-tabs>
			<div class="uu-screen">
				<el-form :inline="true">
					<el-form-item>
						<el-select v-model="paramForm.return_type" clearable @change="getRefundList">
							<template slot="prefix">{{ $t('seller.screen.refundType') }}</template>
							<el-option v-for="(item,index) in $t('seller.options.refundType')" :key="index" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="paramForm.return_status" clearable @change="getRefundList">
							<template slot="prefix">{{ $t('seller.screen.refundStatus') }}</template>
							<el-option v-for="(item,index) in $t('seller.options.refundStatus')" :key="index" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="paramForm.return_reason" clearable @change="getRefundList">
							<template slot="prefix">{{ $t('seller.screen.reason') }}</template>
							<el-option :label="$t('seller.unit.all')" value=""></el-option>
							<el-option v-for="(item,index) in reasonOptions" :key="index" :label="item" :value="item"></el-option>
						</el-select>
					</el-form-item>
					<!-- <el-form-item>
						<el-select v-model="paramForm.return_logistics" class="uu-prefix-100" clearable @change="getRefundList">
							<template slot="prefix">{{ $t('seller.screen.returnLogisticsMode') }}</template>
							<el-option v-for="(item,index) in $t('seller.options.returnLogistics')" :key="index" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="paramForm.deposit_status" clearable @change="getRefundList">
							<template slot="prefix">{{ $t('seller.screen.trustStatus') }}</template>
							<el-option v-for="(item,index) in $t('seller.options.depositStatus')" :key="index" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="paramForm.logistics_status" class="uu-prefix-100" clearable @change="getRefundList">
							<template slot="prefix">{{ $t('seller.screen.returnLogisticsStatus') }}</template>
							<el-option v-for="(item,index) in $t('seller.options.logisticsStatus')" :key="index" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item>
					<el-form-item>
						<el-select v-model="paramForm.waiter_intervene" clearable @change="getRefundList">
							<template slot="prefix">{{ $t('seller.screen.waiterIntervene') }}</template>
							<el-option v-for="(item,index) in $t('seller.options.waiterIntervene')" :key="index" :label="item.label" :value="item.value"></el-option>
						</el-select>
					</el-form-item> -->
				</el-form>
			</div>
			<div class="uu-action">
				<!-- <el-button @click="handleRefundGoods" round>{{ $t('seller.actions.batchReturn') }}</el-button> -->
				<!-- <el-button @click="handleMoneyBack" round>{{ $t('seller.actions.batchRefund') }}</el-button> -->
				<!-- <el-button round>{{$t('seller.actions.trustSet')}}</el-button> -->
				<!-- <el-button round>{{$t('seller.actions.export')}}</el-button> -->
				<el-button @click="$router.push({ name: 'logisTools', params: { type: 'address' } })" round>
					{{ $t('seller.actions.addressTemplates') }}</el-button>
			</div>
			<div class="uu-refund-list">
				<table width="100%" cellpadding="2">
					<thead>
						<tr>
							<th width="45px">
								<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll"
									@change="handleCheckAllChange">{{ }}</el-checkbox>
							</th>
							<th style="min-width: 200px;">{{ $t('seller.tableHead.baby') }}</th>
							<th>{{ $t('seller.tableHead.orderAmount') }}</th>
							<th>{{ $t('seller.tableHead.refundAmount') }}</th>
							<th>{{ $t('seller.tableHead.latelyApplyTime') }}</th>
							<th>{{ $t('seller.tableHead.reason') }}</th>
							<th>{{ $t('seller.tableHead.returnLogistics') }}</th>
							<th>{{ $t('seller.tableHead.shippingLogistics') }}</th>
							<th>{{ $t('seller.tableHead.refundStatus') }}</th>
						</tr>
					</thead>
					<template v-if="refundList.length > 0">
						<tbody v-for="item, index in refundList" :key="index">
							<tr class="group-header">
								<td align="center">
									<el-checkbox v-model="checkIds" :label="item.apply_refund_id"
										@change="handleCheckedChange">{{ }}</el-checkbox>
								</td>
								<td colspan="8">
									<div class="item-head">
										<ul>
											<li><span>{{$t('seller.order.refundNumber')}}：{{ item.trade_no }}</span></li>
											<li><span>{{$t('seller.order.number2')}}：{{ item.order_sn }}</span></li>
											<!-- <li><span>{{$t('seller.order.goodsCode')}}：{{ item.order_goods_info.goods_id }}</span></li> -->
											<li><a href="javascript:void(0);">{{ item.member_info.real_name }}</a></li>
										</ul>
									</div>
								</td>
							</tr>
							<tr>
								<td colspan="2">
									<div class="item-goods" v-for="(goods,index2) in item.order_info.order_goods" :key="index2">
										<div class="pic"><img :src="goods.goods_image"
												@error="imageLoadError" alt=""></div>
										<div class="item-main">
											<div class="name"><a href="javascript:void(0);">{{ goods.goods_name }}</a></div>
											<div class="desc">{{ goods.specification }}</div>
											<div class="price">￥<span>{{ goods.price }}</span></div>
										</div>
									</div>
								</td>
								<td align="center">￥{{ item.order_info.order_amount }}</td>
								<td align="center"><span class="red">￥{{ item.actual_price | priceFilter() }}</span></td>
								<td align="center">{{ item.create_time | parseTime() }}</td>
								<td align="center">{{ item.reason }}</td>
								<td align="center">
									<template v-if="item.logistics_number">
										<p>{{item.logistics_number}}</p>
										<p>({{item.logistics_company || '快递'}})</p>
									</template>
									<span class="red" v-else>-</span>
								</td>
								<td align="center"><span class="red">{{ item.order_info.delivery_status | statusFilter($t('seller.filters.deliveryStatus')) }}</span></td>
								<td align="center">
									<ul class="item-status">
										<li><router-link :to="{ name: 'refundDetail', params: {id: item.apply_refund_id}}">{{ refundStatusText(item) }}</router-link></li>
										<!-- <li><span class="red">还剩0时6分30秒</span></li> -->
									</ul>
								</td>
							</tr>
						</tbody>
					</template>
					<tbody v-else>
						<tr>
							<td colspan="8" align="center"><span style="color: #999999;">{{ $t('seller.empty.nodata')
							}}</span>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="uu-foot">
				<el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
					:current-page.sync="pagecurr" :page-size="pagesize" layout="total, prev, pager, next"
					:prev-text="$t('seller.pagination.prevText')" :next-text="$t('seller.pagination.nextText')"
					:total="listTotal">
				</el-pagination>
			</div>
		</div>
	</el-scrollbar>
</template>

<script>
import * as echarts from 'echarts';
import { fetchRefundList, fetchRefundStatics, fetchRefundReason, refundGoods, moneyBack } from "@/api/seller/order";
import theme from "@/assets/css/mixin.scss"

const defaultScreen = {
	buyer_name: '',
	waybill_sn: '',
	order_sn: '',
	price_from: '',
	price_to: '',
	refund_sn: '',
	refund_time: '1'
}
const defaultParams = {
	return_type: '',
	return_status: '1',
	return_reason: '',
	return_logistics: '0',
	deposit_status: '0',
	logistics_status: '0',
	waiter_intervene: '0'
}

export default {
    filters: {
        statusFilter(status, option) {
            return option[status]
        }
    },
	data() {
		return {
			screenForm: Object.assign({},defaultScreen),
			startPickerOptions: {
				disabledDate: (time) => {
					return time.getTime() > Date.now();
				}
			},
			applyTimes: [
				new Date(new Date().getFullYear(),new Date().getMonth()-3,new Date().getDate(),new Date().getHours(),new Date().getMinutes(),new Date().getSeconds()).getTime(),
				Date.now()
			],
			modifyTimes: '',
			activeTabs: '0',
			refundNav: this.$t('seller.order.refundNav'),
			paramForm: Object.assign({},defaultParams),
			reasonOptions: [],
			pagecurr: 1,
			pagesize: 10,
			listTotal: 0,
			refundList: [],
			isIndeterminate: false,
			checkAll: false,
			checkIds: []
		}
	},
	created() {
		this.getRefundList()
		this.getReasonOptions()
		this.getRefundStatics()
	},
	methods: {
		imageLoadError(e) {
			e.target.src = this.$imageErrorGoods;
			e.target.style.objectFit = 'fill';
		},
		getRefundList() {
			const params = {
				page_index: (this.pagecurr - 1) * this.pagesize,
				page_size: this.pagesize,
				goods_status: this.activeTabs,
				...this.screenForm,
				...this.paramForm
			}
			if(this.applyTimes) {
				params.apply_start_time = parseInt(this.applyTimes[0]/1000)
				params.apply_end_time = parseInt(this.applyTimes[1]/1000)
			}
			if(this.modifyTimes) {
				params.modify_start_time = parseInt(this.modifyTimes[0]/1000)
				params.modify_end_time = parseInt(this.modifyTimes[1]/1000)
			}
			fetchRefundList(params).then(response => {
				const { data } = response
				this.refundList = data.list
				this.listTotal = parseInt(data.count)
			})
		},
		getReasonOptions() {
			fetchRefundReason({ uuid: this.$store.getters.uuid }).then(response => {
				const { data } = response
				this.reasonOptions = data.reasons
			})
		},
		getRefundStatics() {
			fetchRefundStatics({ uuid: this.$store.getters.uuid }).then(response=> {
				const { data } = response
				this.initEcharts(data)
			})
		},
		initEcharts(data) {
			const domeList = [
				{ value: data.jj_num, name: '商家已拒绝' },
				{ value: data.p_tk_num, name: '退款待处理' },
				{ value: data.p_s_sh_num, name: '待商家收货' },
				{ value: data.p_b_fh_num, name: '待买家发货' }
			]
			const total = data.uf_num
			var myChart = echarts.init(document.getElementById('myChart'));

			// 绘制图表
			myChart.setOption({
				title: {
					text: '三个月内未完结：'+ total,
					textStyle: {
						fontWeight: 'normal'
					},
					left: '45%',
					top: '15%'
				},
				tooltip: {
					trigger: 'item',
					formatter: '{a} <br/>{b} : {c} ({d}%)'
				},
				legend: {
					top: '40%',
					left: '45%',
					width: 250,
					icon: 'circle',
					formatter: function(name) {
						var tarVal;
						for(var i = 0; i<domeList.length;i++) {
							if(domeList[i].name == name) {
								tarVal = domeList[i].value
							}
						}
						var v = tarVal
						return `${name} ${v}`
					}
				},
				series: [{
					name: '退款状态',
					type: 'pie',
					radius: ['40%', '70%'],
					avoidLabelOverlap: false,
					right: '55%',
					label: {
						show: false,
						position: 'center'
					},
					labelLine: {
						show: false
					},
					data: [
						{ value: data.p_tk_num, name: '退款待处理', itemStyle: { color: '#6698ff' } },
						{ value: data.p_b_fh_num, name: '待买家发货', itemStyle: { color: '#a0e5ce' } },
						{ value: data.p_s_sh_num, name: '待商家收货', itemStyle: { color: '#51cca3' } },
						{ value: data.jj_num, name: '商家已拒绝', itemStyle: { color: '#b2ccff' } }
					]
				}]
			});
		},
		rangeInputFocus(event) {
			event.target.parentNode.parentNode.style.backgroundColor = '#ffffff';
			event.target.parentNode.parentNode.style.borderColor = theme.colorPrimary;
		},
		rangeInputBlur(event) {
			event.target.parentNode.parentNode.style.backgroundColor = '';
			event.target.parentNode.parentNode.style.borderColor = '';
		},
		handleClickTabs(tab, event) {
			this.pagecurr = 1
			this.getRefundList()
		},
		handleSizeChange(val) {
			this.pagesize = val;
		},
		handleCurrentChange(val) {
			this.pagecurr = val;
			this.getRefundList();
		},
		handelSearch() {
			this.pagecurr = 1
			this.getRefundList()
		},
		handleClear() {
			this.screenForm = Object.assign({},defaultScreen)
			this.applyTimes = ''
			this.modifyTimes = ''
			this.pagecurr = 1
			this.getRefundList()
		},
		refundStatusText(data){
			let str = ''
			if(data.refund_status !== 1) {
				const options = this.$t('seller.filters.refundStatus1')
				str = options[data.refund_status]
			} else {
				const options = this.$t('seller.filters.refundStatus2')
				str = options[data.apply_refund_info.step]
				if(!str) {
					str = options[1]
				}
			}
			return str
		},
		handleCheckAllChange(val) {
			let ids = [];
			if(val){
				this.refundList.forEach(item=>{
					ids.push(item.apply_refund_id);
				})
			}
			this.checkIds = ids;
			this.isIndeterminate = false;
		},
		handleCheckedChange(value) {
			let checkedCount = this.checkIds.length;
			this.checkAll = checkedCount === this.refundList.length;
			this.isIndeterminate = checkedCount > 0 && checkedCount < this.refundList.length;
		},
		handleRefundGoods() {
			if(this.checkIds.length > 0) {
				this.$confirm(this.$t('seller.confirm.agreeRefunds'),'',{
					confirmButtonText: this.$t('seller.actions.confirm'),
					cancelButtonText: this.$t('seller.actions.cancel'),
					type: 'warning',
				}).then(()=>{
					refundGoods({apply_refund_id: this.checkIds.join(',')}).then(() => {
						this.getRefundList()
					})
				}).catch(()=>{ })
			} else {
				this.$message.error(this.$t('seller.errorMsg.batchRefunds'))
			}
		},
		handleMoneyBack() {
			if(this.checkIds.length > 0) {
				this.$confirm(this.$t('seller.confirm.agreeRefund'),'',{
					confirmButtonText: this.$t('seller.actions.confirm'),
					cancelButtonText: this.$t('seller.actions.cancel'),
					type: 'warning',
				}).then(()=>{
					moneyBack({apply_refund_id: this.checkIds.join(',')}).then(() => {
						this.getRefundList()
					})
				}).catch(()=>{ })
			} else {
				this.$message.error(this.$t('seller.errorMsg.batchRefund'))
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.uu-return {
	padding: 18px 24px;
	.uu-charts {
		height: 180px;
		border-radius: 12px;
		display: flex;
		align-items: center;
		margin-bottom: 18px;
		background-color: #f7f8fa;
	}
	.uu-screen {

		margin: 0 -9px;

		::v-deep .el-form-item {
			width: calc(25% - 18px);
			margin: 0px 9px 18px;

			.el-form-item__content {
				.el-select {
					width: 100%;
				}
			}
		}

		::v-deep .el-form--inline {
			.el-form-item__content {
				width: 100%;

				.el-input__inner {
					background-color: $--seller-filter-input-background;
				}

				.el-input__inner:focus,
				.el-input__inner:hover {
					background-color: #FFFFFF;
					border-color: $--seller-primary;
				}

				.el-input--prefix {
					.el-input__inner {
						padding-left: 72px;
					}
				}

				.uu-prefix-100 {
					.el-input--prefix {
						.el-input__inner {
							padding-left: 100px;
						}
					}
				}

				.el-date-editor--datetimerange.el-input__inner {
					width: 100%;
					padding-left: 52px;
				}

				.uu-date-picker {
					position: relative;

					.picker-prefix-label {
						top: 0;
						left: 10px;
						z-index: 9;
						height: 100%;
						color: #C0C4CC;
						text-align: center;
						position: absolute;
					}
				}
			}
		}

		::v-deep .el-input__prefix {
			left: 10px;
		}

		.range-number {
			line-height: 34px;
			padding: 2px 0;
			display: flex;

			.input-medium {
				flex: 1;
				width: 60px;
				height: 34px;
				line-height: 34px;
				border-radius: 4px;
				background-color: $--seller-filter-input-background;
				padding: 0 9px;
				box-sizing: border-box;
			}

			.input-medium:focus,
			.input-medium:hover {
				background-color: #FFFFFF;
			}

			.range-separate {
				padding: 0 5px;
			}
		}

		.clear-btn {
			cursor: pointer;
			color: #666666;
			margin-left: 12px;

			i {
				margin-right: 4px;
			}
		}

		.clear-btn:hover {
			color: $--seller-primary;
		}
	}

	.uu-action {
		margin-bottom: 15px;
	}

	.uu-refund-list {


		thead:after,
		tbody:after {
			content: "";
			display: block;
			height: 6px;
		}

		th,
		td {
			padding: 12px;
			line-height: 20px;
			font-size: 14px;
		}

		th {
			background-color: $--seller-thead-background-2;
		}

		th:first-child {
			border-top-left-radius: 9px;
			border-bottom-left-radius: 9px;
		}

		th:last-child {
			border-top-right-radius: 9px;
			border-bottom-right-radius: 9px;
		}

		.group-header {
			td {
				background-color: #f7f8fa;
			}

			td:first-child {
				border-top-left-radius: 9px;
				border-bottom-left-radius: 9px;
			}

			td:last-child {
				border-top-right-radius: 9px;
				border-bottom-right-radius: 9px;
			}
		}

		.item-head {
			li {
				display: inline-block;
				margin-right: 30px;
			}
		}

		.item-goods {
			display: flex;
			margin-bottom: 10px;

			.pic {
				img {
					width: 68px;
					height: 68px;
					border-radius: 12px;
				}
			}

			.item-main {
				flex: 1;
				width: 0;
				padding-left: 15px;

				.name {
					max-height: 44px;
					display: block;
					font-size: 14px;
					line-height: 22px;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-box-orient: vertical;
					-webkit-line-clamp: 2;

					a {
						color: $--seller-primary;
					}
				}

				.number {
					color: #777777;
					font-size: 12px;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.desc {
					color: #999999;
					font-size: 12px;
				}
				.price {
					color: #ff5000;
					// font-size: 12px;
					margin-top: 10px;
				}
			}
			&:last-child {
				margin-bottom: 0;
			}
		}

		.item-status {
			li {
				text-align: center;
				padding: 5px 0;

				a {
					color: $--seller-primary;
				}

				span {
					font-size: 12px;
					color: #777777;
					&.red {
						color: #ff5000;
					}
				}
			}
		}

		.item-price {
			li {
				text-align: center;

				span {
					font-size: 12px;
					color: #777777;
				}
			}
		}
		.red {
			color: #ff5000;
		}
	}

	::v-deep .el-tabs__header {
		.el-tabs__nav-wrap {
			padding: 0 24px;
		}

		.el-tabs__nav-wrap::after {
			height: 1px;
		}
	}

	::v-deep .has-gutter {
		th {
			background-color: $--seller-thead-background-2;
		}

		th.el-table_1_column_1 {
			border-top-left-radius: 10px;
		}

		th.el-table_1_column_7 {
			border-top-right-radius: 10px;
		}
	}

	.uu-foot {
		padding: 15px 0;
		text-align: center;
	}
}
</style>
